
import React, {useState} from "react"
import Layout from "../../layout"

import WebappsForm from "../../webapps/form"

const ENTITYID="nntag"

const searchFields = [
	{"label":"Name", "dbfield": "nntag_name", "type": "text", "filtertype": "textbox"},
	{"label":"Image", "dbfield": "nntag_iconimage", "type": "image", "filtertype": "image"},
];

const formFields = [
	[
		{"label":"Name", "field": "nntag_name", "value": "", "input": "textbox", "mode": "unique"}
	]
];

const formROFields = [
	[
		{"label":"Name", "field": "nntag_name", "value": "", "input": "textbox", "mode": "readonly"}
	]
];

const formTailFields = [
	[
		{"label":"Image", "field": "nntag_iconimage", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":2048, "imageheight":2048, "imageformat":"png", "filehost": "cdn.ngnw.ph", "filelocation":"images/tagicon/uploads", "uploadtype":"https"}
	]
];

const BrandeditorPage = ({location, allowadd}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	return <Layout location={location}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={allowadd}
				allowdelete={false}
				pagetitle={pagetitle}
				mobilerowtitlefield={["nntag_name"]}
				searchFields={searchFields}
				editFields={allowadd?formFields.concat(formTailFields):formROFields.concat(formTailFields)}
				newformFields={allowadd?formFields.concat(formTailFields):[]}
				userSearchFilter={[{"field":"nntagalias_id", "operation":"=", "value": 1}]}
				entity={ENTITYID}
				token={token} />
		</Layout>
}


export default BrandeditorPage;
